@font-face {
  font-family: 'OpenSans';
  src: url('../../../public/fonts/OpenSans-ExtraBold.woff2');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../public/fonts/OpenSans-Bold.woff2');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../public/fonts/OpenSans-SemiBold.woff2');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Arial';
  src: url('../../../public/fonts/Arial-Bold.woff2');
  font-display: swap;
  font-weight: 700;
}