@import '@/shared/scss-utils/breakpoints';

.section {
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 117px;
  padding-bottom: 143px;

  @media (max-width: 1700px) {
    padding-bottom: 110px;
  }

  @include laptop {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  @include ipad {
    justify-content: flex-start;
    padding-top: 32px;
    padding-bottom: 40px;
  }
}

.decor {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.decor1,
.decor2,
.decor3,
.decor4,
.decor5,
.decor6 {
  @include laptop {
    scale: 0.8;
  }
}

.decor1 {
  width: 150px;
  height: 147px;
  top: -50px;
  left: 111px;

  @include ipad {
    display: none;
  }
}

.decor2 {
  width: 170px;
  height: 161px;
  top: -61px;
  left: calc(50% + 138px);
  transform: translateX(-50%);

  @include ipad {
    display: none;
  }
}

.decor3 {
  width: 200px;
  height: 168px;
  top: 24px;
  right: 15px;

  @include ipad {
    display: none;
  }
}

.decor4 {
  width: 243px;
  height: 222px;
  top: 100px;
  left: -91px;

  @include ipad {
    display: none;
  }
}

.decor4 {
  width: 243px;
  height: 222px;
  top: 100px;
  left: -91px;

  @include ipad {
    display: none;
  }
}

.decor5 {
  width: 133px;
  height: 137px;
  top: 88px;
  left: 25%;

  @include tablet {
    display: none;
  }
}

.decor6 {
  width: 179px;
  height: 150px;
  top: 142px;
  right: 21%;

  @include laptop {
    right: 24%;
  }

  @include tablet {
    display: none;
  }
}

.decor7 {
  width: 801px;
  height: 988px;
  bottom: 0;
  left: 0;

  @media (max-width: 1700px) {
    left: -100px;
    width: 748px;
    height: 923px;
  }

  @include laptop {
    width: 439px;
    height: 542px;
  }

  @include tablet {
    width: 492px;
    height: 607px;
    right: -200px;
    bottom: 0;
  }

  @include ipad {
    display: none;
  }
}

.decor8 {
  width: 627px;
  height: 969px;
  bottom: 54px;
  right: 45px;

  @media (max-width: 1700px) {
    right: -100px;
  }

  @include laptop {
    right: -150px;
    width: 580px;
    height: 896px;
  }

  @include tablet {
    right: -200px;
    bottom: 0;
  }

  @include ipad {
    width: 420px;
    height: 649px;
    bottom: 50px;
    right: -150px;
  }

  @include mobile {
    width: 271px;
    height: 425px;
    top: calc(50% - 5px);
    bottom: auto;
    transform: translateY(-50%);
    right: -73px;
  }
}

.decor9 {
  width: 864px;
  height: 890px;
  bottom: 0;
  right: 0;

  @media (max-width: 1700px) {
    right: -250px;
  }

  @include laptop {
    width: 768px;
    height: 791px;
  }

  @include tablet {
    display: none;
  }
}

.decor10 {
  width: 203px;
  height: 106px;
  bottom: 0;
  left: calc(50% + 74px);
  transform: translateX(-50%);

  @include laptop {
    width: 143px;
    height: 74px;
    left: calc(50% - 60px);
  }

  @include tablet {
    left: calc(50% + 160px);
  }

  @include ipad {
    width: 123px;
    height: 64px;
    filter: blur(3px);
  }

  @include mobile {
    left: calc(50% + 80px);
  }
}

.decor11,
.decor12,
.decor13 {
  display: none;

  @include ipad {
    display: block;
  }
}

.decor11 {
  width: 65px;
  height: 63px;
  top: 34px;
  right: -21px;
}

.decor12 {
  position: absolute;
  width: 57px;
  height: 57px;
  top: 110px;
  left: -17px;
}

.decor13 {
  width: 134px;
  height: 106px;
  top: 270px;
  left: -42px;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include mobile {
    padding: 0 12px;
  }
}

.logo {
  display: block;
  width: 646px;
  height: 360px;
  margin-bottom: 32px;

  @media (max-width: 1700px) {
    width: 490px;
    height: 273px;
  }

  @include laptop {
    width: 379px;
    height: 211px;
    margin-bottom: 20px;
  }

  @include ipad {
    width: 234px;
    height: 130px;
    margin-bottom: 16px;
  }
}

.title {
  max-width: 1042px;
  margin: 0 auto;
  text-align: center;
  font-size: 48px;
  line-height: 65px;
  margin-bottom: 42px;
  text-transform: uppercase;

  @include laptop {
    max-width: 700px;
    font-size: 30px;
    line-height: 50px;
  }

  @include ipad {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 44px;
    font-family: var(--second-family);
  }
}

.list {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 40px;

  @include laptop {
    margin-bottom: 30px;
  }

  @include tablet {
    flex-wrap: wrap;
    max-width: 900px;
    justify-content: center;
  }

  @include mobile {
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 38px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 85px;
  min-width: 355px;
  border-radius: 10px;
  background-color: #0b1f20;
  border: 1px solid #1f2e30;
  box-shadow: inset 0 0 4px 0 rgba(255, 255, 255, 0.25), 0 0 20px 0 rgba(0, 255, 242, 0.77);

  @include laptop {
    min-width: 312px;
  }

  @include tablet {
    padding: 0 20px;
    justify-content: flex-start;
  }

  @include mobile {
    min-width: auto;
    padding: 0 20px;
  }

  &:first-child .label {
    max-width: 144px;

    @include mobile {
      max-width: 128px;
    }
  }
}

.icon {
  display: block;
  width: 46px;
  height: 46px;
}

.label {
  line-height: 25px;

  @include laptop {
    font-size: 16px;
  }

  @include mobile {
    line-height: 22px;
  }
}

.btn {
  display: inline-block;
  font-size: 48px;
  line-height: 65px;
  font-weight: 800;
  color: var(--color-dark);
  padding: 18px 124px 19px;
  letter-spacing: 0.1px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #FCCE1B;
  box-shadow: 0 0 11px 0 #fcce1b;
  margin-bottom: 8px;

  @include laptop {
    padding: 13px 80px;
    font-size: 30px;
    line-height: 45px;
  }

  @include mobile {
    text-align: center;
    padding: 16px 0;
    font-size: 34px;
    line-height: 46px;
    width: 100%;
    margin-bottom: 0px;
  }

  &:hover {
    background-color: #ffdd62;
  }
}

.platforms {
  display: flex;
  align-items: center;
  gap: 7px;

  @include laptop {
    gap: 15px;
  }
}

.platformItem {
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    @include laptop {
      max-width: 100%;
    }
  }
}