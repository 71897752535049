*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  min-width: var(--min-width);
  min-height: 100%;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-white);
  background-color: #001114;
  background-image: url('../../../public/images/main-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  padding: 0;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
}

#root {
  position: relative;
  z-index: 2;
  overflow-x: hidden;
  min-height: 100vh;
}