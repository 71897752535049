:root {
  // base
  --font-family: 'OpenSans', sans-serif;
  --second-family: 'Arial', sans-serif;

  // colors
  --color-white: #fff;
  --color-dark: #211D04;

  // other
  --min-width: 320px;
}
